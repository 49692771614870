<template>
  <aside class="filters active">
    <h1>
      <label>{{ $t("overall.filters") }}</label>
    </h1>
    <button class="reinit" @click="reinitialize">{{ $t("overall.reinitialize") }}</button>
    <div class="box">
      <label>{{ $t("properties.price") }}</label>
      <div class="d-flex justify-content-between">
        <div class="box" style="width: 46%">
          <div class="input-wrapper">
            <input type="number" placeholder="From" v-model="filters.from_price" />
            <span class="unit">&euro;</span>
          </div>
        </div>
        <div class="box" style="width: 46%">
          <div class="input-wrapper">
            <input type="number" placeholder="To" v-model="filters.to_price" />
            <span class="unit">&euro;</span>
          </div>
        </div>
      </div>
    </div>
    <div class="element">
      <label>{{ $t("properties.status.title") }}</label>
      <Select
        v-model="filters.status"
        :options="[
          { name: $t('overall.not_specified'), value: null },
          { name: $t('properties.status.sell'), value: 'sell' },
          { name: $t('properties.status.rent'), value: 'rent' },
          { name: $t('properties.status.sold'), value: 'sold' },
          { name: $t('properties.status.booked'), value: 'booked' },
          { name: $t('properties.status.stock_in_trade'), value: 'stock_in_trade' },
        ]"
      />
    </div>
    <div class="box">
      <label>{{ $t("properties.type.title") }}</label>
      <Select
        v-model="filters.type"
        :options="[
          { name: $t('overall.not_specified'), value: null },
          { name: $t('properties.type.flat'), value: 'flat' },
          { name: $t('properties.type.villa'), value: 'villa' },
          { name: $t('properties.type.office'), value: 'office' },
          { name: $t('properties.type.house'), value: 'house' },
          { name: $t('properties.type.land'), value: 'field' },
          { name: $t('properties.type.garage'), value: 'garage' },
          { name: $t('properties.type.bungalow'), value: 'bungalow' },
          { name: $t('properties.type.duplex'), value: 'duplex' },
          { name: $t('properties.type.professional_office'), value: 'business_local' },
          { name: $t('properties.type.building'), value: 'building' },
          { name: $t('properties.type.industrial_land'), value: 'industrial_land' },
          { name: $t('properties.type.agricultural_land'), value: 'agricultural_land' },
          { name: $t('properties.type.penthouse'), value: 'penthouse' },
        ]"
      />
    </div>

    <div class="box">
      <label>{{ $t("properties.bedrooms") }}</label>
      <Select
        v-model="filters.bedrooms"
        :options="[
          { name: $t('overall.not_specified'), value: null },
          { name: '1', value: 1 },
          { name: '2', value: 2 },
          { name: '3', value: 3 },
          { name: '4', value: 4 },
          { name: '5', value: 5 },
          { name: '6', value: 6 },
          { name: '7', value: 7 },
          { name: '8', value: 8 },
        ]"
      />
    </div>
    <div class="box">
      <label>{{ $t("properties.bathrooms") }}</label>
      <Select
        v-model="filters.bathrooms"
        :options="[
          { name: $t('overall.not_specified'), value: null },
          { name: '1', value: 1 },
          { name: '2', value: 2 },
          { name: '3', value: 3 },
          { name: '4', value: 4 },
          { name: '5', value: 5 },
          { name: '6', value: 6 },
          { name: '7', value: 7 },
          { name: '8', value: 8 },
        ]"
      />
    </div>
    <div class="box" style="margin-top: 20px">
      <label>Coast</label>
      <Select
        v-model="filters.costa"
        :options="[
          { name: $t('overall.not_specified'), value: null },
          { name: 'Costa Blanca South', value: 'south' },
          { name: 'Costa Blanca North', value: 'north' },
          { name: 'Costa Calida', value: 'calida' },
          { name: 'Costa Almeria', value: 'almeria' },
          { name: 'Costa Del Sol', value: 'delsol' },
        ]"
      />
    </div>
  </aside>
</template>

<script>
import Select from "@/components/Select";
export default {
  name: "ListFilters",
  props: {
    value: {
      required: true,
    },
  },
  watch: {
    filters: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  methods: {
    reinitialize() {
      this.filters = {
        price: null,
        status: null,
        costa: null,
        type: null,
        from_price: null,
        to_price: null,
        bathrooms: null,
        bedrooms: null,
      };
    },
  },
  components: {
    Select,
  },
  data() {
    return {
      filters: {
        price: null,
        status: null,
        type: null,
        from_price: null,
        to_price: null,
        bathrooms: null,
        bedrooms: null,
      },
    };
  },
  mounted() {
    this.$emit("input", this.filters);
  },
};
</script>
