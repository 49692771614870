<template>
  <div class="property-list-container">
    <Header
      :details="{
        back: false,
        title: 'Properties',
      }"
    />
    <ListFilters v-model="filters" v-show="isFilters" />
    <div class="wrapper-content" :style="computedFilters">
      <header class="header-list d-flex align-items-center">
        <div class="flex sub-header-options">
          <div>
            <button class="show-filter cta" @click="isFilters = !isFilters">{{ $t("overall.filter") }}</button>
          </div>
          <div>
            <div class="search"><input type="text" v-model="search" :placeholder="$t('overall.search')" /></div>
          </div>
          <router-link :to="{ name: 'create-property' }" class="cta align-right">
            <plusSVG />
            {{ $t("properties.create") }}
          </router-link>
        </div>
      </header>
      <div class="table-list">
        <ul class="labels">
          <li @click="orderListBy('reference', properties)" class="table-list__line table-list__line--fixed-width">
            <button>{{ $t("overall.reference") }}</button>
          </li>
          <li @click="orderListBy('translations.0.title', properties)" class="table-list__line">
            <button>{{ $t("properties.title.title") }}</button>
          </li>
          <li @click="orderListBy('type', properties)" class="table-list__line">
            <button>{{ $t("properties.type.title") }}</button>
          </li>
          <li @click="orderListBy('status', properties)" class="table-list__line">
            <button>{{ $t("properties.status.title") }}</button>
          </li>
          <li @click="orderListBy('address.city', properties)" class="table-list__line">
            <button>{{ $t("overall.address") }}</button>
          </li>
          <li @click="orderListBy('price', properties)" class="table-list__line">
            <button>{{ $t("properties.price") }}</button>
          </li>
          <li @click="orderListBy('price', properties)" class="table-list__line table-list__line--fixed-width table-list__line--property-activated">
            <button>Active</button>
          </li>
        </ul>
        <div class="elements">
          <template v-if="!loading">
            <div class="line" v-for="p in elementsToDisplay" :key="p.token">
              <div class="table-list__line table-list__line--fixed-width">
                <router-link :to="{ name: 'edit-property', params: { token: p.token } }">
                  {{ p.reference ? p.reference : "-" }}
                </router-link>
              </div>
              <div class="table-list__line">
                <router-link :to="{ name: 'edit-property', params: { token: p.token } }">
                  {{ p.translations[0] && p.translations[0].title ? p.translations[0].title : "-" }}
                </router-link>
              </div>
              <div class="table-list__line">
                <router-link :to="{ name: 'edit-property', params: { token: p.token } }">
                  {{ p.type ? $t("properties.type." + p.type) : "-" }}
                </router-link>
              </div>
              <div class="table-list__line">
                <router-link :to="{ name: 'edit-property', params: { token: p.token } }">
                  {{ p.status ? $t("properties.status." + p.status) : "-" }}
                </router-link>
              </div>
              <div class="table-list__line">
                <router-link :to="{ name: 'edit-property', params: { token: p.token } }">
                  {{ p.address && p.address.city ? p.address.city : "-" }}
                </router-link>
              </div>
              <div class="table-list__line">
                <router-link style="display: block" :to="{ name: 'edit-property', params: { token: p.token } }">
                  {{ p.price ? p.price : "-" | splitNumber }} {{ p.price ? $store.getters.getCurrency : "" }}
                </router-link>
              </div>
              <div class="table-list__line table-list__line--fixed-width table-list__line--center-xy table-list__line--property-activated">
                <ToggleButton v-model="p.is_activated" @input="onItemActivatedChange($event, p)"/>
              </div>
            </div>
          </template>
          <span class="no-element" v-else>{{ $t("properties.loading_properties") }}</span>
          <span class="no-element" v-if="properties.length < 1 && !loading">{{ $t("properties.no_property") }}</span>
        </div>
      </div>
      <Pagination v-if="properties.length" :value="properties" @changeCurrentPage="elementsToDisplay = $event" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import plusSVG from "@/assets/img/plus.svg";
import ListFilters from "@/pages/properties/ListFilters";
import modalBus from "@/bus/modal-bus";

export default Vue.extend({
  components: { plusSVG, ListFilters },
  name: "PropertiesList",
  computed: {
    computedFilters() {
      if (this.isFilters) {
        return {
          width: "calc(100% - 300px)",
          "margin-left": "300px",
        };
      }
      return {};
    },
    paramsURLFilters() {
      let url = " ";
      for (let prop in this.filters) {
        if (this.filters[prop] !== null) {
          if (prop === "bedrooms" || prop === "bathrooms") {
            url += prop + "[e]=" + this.filters[prop] + "&";
          } else if (prop === "from_price") {
            url += "minPrice=" + this.filters[prop] + "&";
          } else if (prop === "to_price") {
            url += "maxPrice=" + this.filters[prop] + "&";
          } else if (prop === "type" || prop === "status" || prop === "costa") {
            url += prop + "[]=" + this.filters[prop] + "&";
          }
        }
      }
      url = url.substr(0, url.length - 1);
      return url;
    },
  },
  watch: {
    search() {
      clearTimeout(this.timer);
      this.timer = null;
      this.timer = setTimeout(() => {
        this.loading = true;
        this.$axios.get("/api/properties/search?" + this.paramsURLFilters + "&search=" + this.search).then((res) => {
          this.properties = res.data;
          this.loading = false;
          this.timer = null;
          if (!this.properties.length) {
            this.elementsToDisplay = [];
          }
        });
      }, 300);
    },
    filters: {
      handler() {
        this.$axios.get("/api/properties/search?" + this.paramsURLFilters + "&search=" + this.search).then((res) => {
          this.properties = res.data;
          if (!this.properties.length) {
            this.elementsToDisplay = [];
          }
        });
      },
      deep: true,
    },
  },
  data() {
    return {
      rangePicker: {
        from: 0,
        to: 100000,
      },
      elementsToDisplay: [],
      timer: null,
      isFilters: false,
      properties: [],
      filters: {},
      search: "",
      loading: true,
      sort: {
        name: "",
        direction: "asc",
      },
    };
  },
  methods: {
    popupAddClient() {
      modalBus.$emit("change", {
        component: "AddClient",
      });
      modalBus.$emit("toggle");
    },
    onItemActivatedChange(value, item){
      console.log("change !", item, value,)
      this.$axios.post(`/api/properties/${item.token}/update-activated`, { is_activated: value });
    }
  },
  created() {
    this.$axios.get("/api/properties?" + this.paramsURLFilters).then((res) => {
      this.properties = res.data;
      this.loading = false;
    });
  },
});
</script>

<style lang="scss" scoped>
.table-list__line {
  &:first-child {
    min-width: 75px;
  }
}
</style>
